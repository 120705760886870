// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button_container {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    /* height: 100%; */
}

.form-group > label {
    margin-top: 0px;
    margin-bottom: 0px;
}

.form-group > input {
    margin-top: 0px;
    margin-bottom: 0px;
}

.form-group {
    margin-top: 0px;
    margin-bottom: 5px;
}

.componenteLabel {
    font-size: clamp(0.9rem, 0.7vw, 2.5rem);
}
.itemLabel {
    font-size: clamp(0.9rem, 0.7vw, 2.5rem);
}
.subTitle2 {
    font-size: clamp(0.9rem, 0.7vw, 2.5rem);
}
.itemContainer {
    padding: 0px;
}
.itemInputContainer {
    padding: 0px 10px 0px 0px;
}
.itemInputContainerPrecio {
    padding: 0px 10px 0px 10px;
}
.cantidadLabel {
    font-size: clamp(0.8rem, 0.5vw, 2.5rem);
}

.btn-sm-cont {
    padding: 0px 0px;
    justify-content: left !important;
}
.btn-sm-cont > button {
    font-size: clamp(0.55rem, 0.6vw, 2.5rem);
}`, "",{"version":3,"sources":["webpack://./src/modules/Platillos/AddPlatilloForm.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,qBAAqB;IACrB,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,uCAAuC;AAC3C;AACA;IACI,uCAAuC;AAC3C;AACA;IACI,uCAAuC;AAC3C;AACA;IACI,YAAY;AAChB;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,0BAA0B;AAC9B;AACA;IACI,uCAAuC;AAC3C;;AAEA;IACI,gBAAgB;IAChB,gCAAgC;AACpC;AACA;IACI,wCAAwC;AAC5C","sourcesContent":[".button_container {\n    display: flex;\n    justify-content: center;\n    align-items: flex-end;\n    /* height: 100%; */\n}\n\n.form-group > label {\n    margin-top: 0px;\n    margin-bottom: 0px;\n}\n\n.form-group > input {\n    margin-top: 0px;\n    margin-bottom: 0px;\n}\n\n.form-group {\n    margin-top: 0px;\n    margin-bottom: 5px;\n}\n\n.componenteLabel {\n    font-size: clamp(0.9rem, 0.7vw, 2.5rem);\n}\n.itemLabel {\n    font-size: clamp(0.9rem, 0.7vw, 2.5rem);\n}\n.subTitle2 {\n    font-size: clamp(0.9rem, 0.7vw, 2.5rem);\n}\n.itemContainer {\n    padding: 0px;\n}\n.itemInputContainer {\n    padding: 0px 10px 0px 0px;\n}\n.itemInputContainerPrecio {\n    padding: 0px 10px 0px 10px;\n}\n.cantidadLabel {\n    font-size: clamp(0.8rem, 0.5vw, 2.5rem);\n}\n\n.btn-sm-cont {\n    padding: 0px 0px;\n    justify-content: left !important;\n}\n.btn-sm-cont > button {\n    font-size: clamp(0.55rem, 0.6vw, 2.5rem);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
