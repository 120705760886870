// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card {
    border: 3px solid #ccc;
    border-radius: 5px;
    box-shadow: 3px 3px 10px rgba(94, 94, 94, 0.528);
}

.body-card {
    margin: 10px;
}

.body-card > .div-button {
    align-items: center;
    justify-content: center;
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
}

.body-card > .div-button > .btn-outline-light {
    background-color: #9fe4ff;
    color: black;
}

.card-title {
    font-size: clamp(1.2rem, 1.1vw, 2rem) !important;
    text-align: center;
}

.card-subtitle {
    margin-bottom: 3px;
    font-size: clamp(1rem, 0.9vw, 1.5rem) !important;
}

.card-price {
    margin-bottom: 3px;
    font-size: clamp(1.2rem, 1.2vw, 2rem) !important;
}

.botones-card > .btn {
    margin-right: 5px;
}`, "",{"version":3,"sources":["webpack://./src/modules/Insumos/Producto_Card.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,kBAAkB;IAClB,gDAAgD;AACpD;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;IACb,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,yBAAyB;IACzB,YAAY;AAChB;;AAEA;IACI,gDAAgD;IAChD,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,gDAAgD;AACpD;;AAEA;IACI,kBAAkB;IAClB,gDAAgD;AACpD;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":[".card {\n    border: 3px solid #ccc;\n    border-radius: 5px;\n    box-shadow: 3px 3px 10px rgba(94, 94, 94, 0.528);\n}\n\n.body-card {\n    margin: 10px;\n}\n\n.body-card > .div-button {\n    align-items: center;\n    justify-content: center;\n    display: flex;\n    margin-top: 10px;\n    margin-bottom: 10px;\n}\n\n.body-card > .div-button > .btn-outline-light {\n    background-color: #9fe4ff;\n    color: black;\n}\n\n.card-title {\n    font-size: clamp(1.2rem, 1.1vw, 2rem) !important;\n    text-align: center;\n}\n\n.card-subtitle {\n    margin-bottom: 3px;\n    font-size: clamp(1rem, 0.9vw, 1.5rem) !important;\n}\n\n.card-price {\n    margin-bottom: 3px;\n    font-size: clamp(1.2rem, 1.2vw, 2rem) !important;\n}\n\n.botones-card > .btn {\n    margin-right: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
