// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.containerTablaCostoInsumo {
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: #e5e5e5;
}

.containerTablaCostoInsumo > .row {
    padding-left: 20px;
    padding-right: 20px;
}`, "",{"version":3,"sources":["webpack://./src/modules/CalcularPrecios/TablaCostoInsumo.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,oBAAoB;IACpB,yBAAyB;AAC7B;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;AACvB","sourcesContent":[".containerTablaCostoInsumo {\n    padding-top: 20px;\n    padding-bottom: 20px;\n    background-color: #e5e5e5;\n}\n\n.containerTablaCostoInsumo > .row {\n    padding-left: 20px;\n    padding-right: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
