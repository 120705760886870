// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.titleOption {
    display: flex;
    padding-left: 10px;
    font-weight: bold;
    color: #00b3ff;
    font-family: 'Salsa', cursive;
    font-size: clamp(1rem, 1.2vw, 2.5rem);
    margin: 0px;
}.titleComponents {
    display: flex;
    padding-left: 10px;
    font-weight: bold;
    color: #00b3ff;
    font-family: 'Salsa', cursive;
    font-size: clamp(1rem, 1.2vw, 2.5rem);
}.titleIngredientes {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: bold;
    color: #00b3ff;
    font-family: 'Salsa', cursive;
    font-size: clamp(1rem, 1.2vw, 2.5rem);
}

.personalizarTitle {
    padding-left: 25px;
}
.personalizarTitle > .faButton {
    padding-bottom: 13px;
}
.personalizarTitle > h2 {
    padding: 0px 20px;
}
.container > div {
    margin-top: 10px;
    padding-left: 15px;
    border-radius: 10px;
    box-shadow: 2px 2px 5px #2d2d2d;
    background-color: #fff;
    color: #000;
    text-align: left;
}
.faButton {
    padding: 0px !important;
}
.conTodoBtn{
    background-color: #fff45a;
    border-radius: 10px;
    box-shadow: 2px 2px 5px #2d2d2d;
}`, "",{"version":3,"sources":["webpack://./src/modules/Ordenes/MeseroPage/DetailsComanda.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,kBAAkB;IAClB,iBAAiB;IACjB,cAAc;IACd,6BAA6B;IAC7B,qCAAqC;IACrC,WAAW;AACf,CAAC;IACG,aAAa;IACb,kBAAkB;IAClB,iBAAiB;IACjB,cAAc;IACd,6BAA6B;IAC7B,qCAAqC;AACzC,CAAC;IACG,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;IAClB,iBAAiB;IACjB,cAAc;IACd,6BAA6B;IAC7B,qCAAqC;AACzC;;AAEA;IACI,kBAAkB;AACtB;AACA;IACI,oBAAoB;AACxB;AACA;IACI,iBAAiB;AACrB;AACA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,mBAAmB;IACnB,+BAA+B;IAC/B,sBAAsB;IACtB,WAAW;IACX,gBAAgB;AACpB;AACA;IACI,uBAAuB;AAC3B;AACA;IACI,yBAAyB;IACzB,mBAAmB;IACnB,+BAA+B;AACnC","sourcesContent":[".titleOption {\n    display: flex;\n    padding-left: 10px;\n    font-weight: bold;\n    color: #00b3ff;\n    font-family: 'Salsa', cursive;\n    font-size: clamp(1rem, 1.2vw, 2.5rem);\n    margin: 0px;\n}.titleComponents {\n    display: flex;\n    padding-left: 10px;\n    font-weight: bold;\n    color: #00b3ff;\n    font-family: 'Salsa', cursive;\n    font-size: clamp(1rem, 1.2vw, 2.5rem);\n}.titleIngredientes {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    text-align: center;\n    font-weight: bold;\n    color: #00b3ff;\n    font-family: 'Salsa', cursive;\n    font-size: clamp(1rem, 1.2vw, 2.5rem);\n}\n\n.personalizarTitle {\n    padding-left: 25px;\n}\n.personalizarTitle > .faButton {\n    padding-bottom: 13px;\n}\n.personalizarTitle > h2 {\n    padding: 0px 20px;\n}\n.container > div {\n    margin-top: 10px;\n    padding-left: 15px;\n    border-radius: 10px;\n    box-shadow: 2px 2px 5px #2d2d2d;\n    background-color: #fff;\n    color: #000;\n    text-align: left;\n}\n.faButton {\n    padding: 0px !important;\n}\n.conTodoBtn{\n    background-color: #fff45a;\n    border-radius: 10px;\n    box-shadow: 2px 2px 5px #2d2d2d;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
