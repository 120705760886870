// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.containerArrowPlatillosButton {
    display: flex;
    align-items: center;
    justify-content: center;
}
.buttonArrowPlatillosSelector {
    background-color: #ffffff;
    outline: none !important;
    border: none !important;
}
.buttonArrowPlatillosSelector:hover > svg {
    color: #7bd3ff;
}
.slider_row_slide{
    justify-content: center;
}
.platilloButton {
    background-color: #ffffff;
    border-radius: 10px;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 85px;
    height: 130px;
    border: none;
}
.platilloButton:hover {
    background-color: #FFC0CB;
}
.disabled {
    opacity: 0.5;
}
.disabled:hover {
    background-color: #d3d3d3;
}
.divImagePlatilloButton {
    margin-bottom: 10px;
}
.textPlatilloButton {
    font-size: 20px;
    font-family: Bebas Neue;
    text-transform: uppercase;
}`, "",{"version":3,"sources":["webpack://./src/modules/Ordenes/MeseroPage/PlatilloSelector.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;AACA;IACI,yBAAyB;IACzB,wBAAwB;IACxB,uBAAuB;AAC3B;AACA;IACI,cAAc;AAClB;AACA;IACI,uBAAuB;AAC3B;AACA;IACI,yBAAyB;IACzB,mBAAmB;IACnB,sBAAsB;IACtB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,WAAW;IACX,aAAa;IACb,YAAY;AAChB;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,YAAY;AAChB;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,mBAAmB;AACvB;AACA;IACI,eAAe;IACf,uBAAuB;IACvB,yBAAyB;AAC7B","sourcesContent":[".containerArrowPlatillosButton {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n.buttonArrowPlatillosSelector {\n    background-color: #ffffff;\n    outline: none !important;\n    border: none !important;\n}\n.buttonArrowPlatillosSelector:hover > svg {\n    color: #7bd3ff;\n}\n.slider_row_slide{\n    justify-content: center;\n}\n.platilloButton {\n    background-color: #ffffff;\n    border-radius: 10px;\n    flex-direction: column;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    width: 85px;\n    height: 130px;\n    border: none;\n}\n.platilloButton:hover {\n    background-color: #FFC0CB;\n}\n.disabled {\n    opacity: 0.5;\n}\n.disabled:hover {\n    background-color: #d3d3d3;\n}\n.divImagePlatilloButton {\n    margin-bottom: 10px;\n}\n.textPlatilloButton {\n    font-size: 20px;\n    font-family: Bebas Neue;\n    text-transform: uppercase;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
