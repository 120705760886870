// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LoadingScreen {
  text-align: center;
}

.Load-logo {
  width: 50%;
  pointer-events: none;
  z-index: 1; /* Añadido para asegurar que esté sobre el fondo */
}
.Load-bkground {
  width: 100%; /* Modificado para ocupar todo el ancho */
  pointer-events: none;
  position: absolute; /* Añadido para posicionar sobre el contenedor */
}

@media (prefers-reduced-motion: no-preference) {
  .Load-logo {
    animation: App-logo-pulse infinite 1s linear;
  }
}

.Load-header {
  position: relative; /* Añadido para establecer como contenedor relativo */
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

@keyframes App-logo-pulse {
  0%, 100% {
    transform: scale(1); /* Estado inicial y final: tamaño normal */
  }
  40%, 60% {
    transform: scale(1.095); /* Estado inicial y final: tamaño normal */
  }
  50% {
    transform: scale(1.1); /* En el punto medio de la animación, aumenta el tamaño */
  }
}

.container-fluid {
  margin-top: 10px;
  margin-bottom: 20px;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,UAAU;EACV,oBAAoB;EACpB,UAAU,EAAE,kDAAkD;AAChE;AACA;EACE,WAAW,EAAE,yCAAyC;EACtD,oBAAoB;EACpB,kBAAkB,EAAE,gDAAgD;AACtE;;AAEA;EACE;IACE,4CAA4C;EAC9C;AACF;;AAEA;EACE,kBAAkB,EAAE,qDAAqD;EACzE,uBAAuB;EACvB,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,6BAA6B;EAC7B,YAAY;AACd;;AAEA;EACE;IACE,mBAAmB,EAAE,0CAA0C;EACjE;EACA;IACE,uBAAuB,EAAE,0CAA0C;EACrE;EACA;IACE,qBAAqB,EAAE,yDAAyD;EAClF;AACF;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;AACrB","sourcesContent":[".LoadingScreen {\n  text-align: center;\n}\n\n.Load-logo {\n  width: 50%;\n  pointer-events: none;\n  z-index: 1; /* Añadido para asegurar que esté sobre el fondo */\n}\n.Load-bkground {\n  width: 100%; /* Modificado para ocupar todo el ancho */\n  pointer-events: none;\n  position: absolute; /* Añadido para posicionar sobre el contenedor */\n}\n\n@media (prefers-reduced-motion: no-preference) {\n  .Load-logo {\n    animation: App-logo-pulse infinite 1s linear;\n  }\n}\n\n.Load-header {\n  position: relative; /* Añadido para establecer como contenedor relativo */\n  background-color: white;\n  min-height: 100vh;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  font-size: calc(10px + 2vmin);\n  color: white;\n}\n\n@keyframes App-logo-pulse {\n  0%, 100% {\n    transform: scale(1); /* Estado inicial y final: tamaño normal */\n  }\n  40%, 60% {\n    transform: scale(1.095); /* Estado inicial y final: tamaño normal */\n  }\n  50% {\n    transform: scale(1.1); /* En el punto medio de la animación, aumenta el tamaño */\n  }\n}\n\n.container-fluid {\n  margin-top: 10px;\n  margin-bottom: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
