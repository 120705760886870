// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-fluid {
    height: 100%; /* Ajusta este valor según necesites */
}

.contenedor-elementos {
    display: flex;
    flex-direction: column;
    height: 100%; /* Ajusta este valor según necesites */
}

.fila-elemento {
    flex: 1 1; /* Esto hace que cada fila-elemento ocupe un tercio del espacio disponible */
}`, "",{"version":3,"sources":["webpack://./src/modules/Ordenes/MeseroPage/OrdersInterface.css"],"names":[],"mappings":"AAAA;IACI,YAAY,EAAE,sCAAsC;AACxD;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,YAAY,EAAE,sCAAsC;AACxD;;AAEA;IACI,SAAO,EAAE,4EAA4E;AACzF","sourcesContent":[".container-fluid {\n    height: 100%; /* Ajusta este valor según necesites */\n}\n\n.contenedor-elementos {\n    display: flex;\n    flex-direction: column;\n    height: 100%; /* Ajusta este valor según necesites */\n}\n\n.fila-elemento {\n    flex: 1; /* Esto hace que cada fila-elemento ocupe un tercio del espacio disponible */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
