// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#CalcularPrecios > .row > .calculadora {
    background-color: white;
    border: 1px solid #ccc;
    box-shadow: 3px 3px 10px rgba(94, 94, 94, 0.528);
    padding: 50px;
}`, "",{"version":3,"sources":["webpack://./src/routes/adminPage.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,sBAAsB;IACtB,gDAAgD;IAChD,aAAa;AACjB","sourcesContent":["#CalcularPrecios > .row > .calculadora {\n    background-color: white;\n    border: 1px solid #ccc;\n    box-shadow: 3px 3px 10px rgba(94, 94, 94, 0.528);\n    padding: 50px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
