// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProductListRow {
    background-color: white;
    border: 3px solid #ccc;
    border-radius: 5px;
    box-shadow: 3px 3px 10px rgba(94, 94, 94, 0.528);
}

.ProductListCol {
    margin: 10px;
}

.ProductListCol > .div-button {
    align-items: center;
    justify-content: center;
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
}

.ProductListCol > .div-button > .btn-outline-light {
    background-color: #9fe4ff;
    color: black;
}

.card-title {
    font-size: clamp(0.8rem, 1.2vw, 2.5rem);
}

.card-subtitle {
    margin-bottom: 3px;
    font-size: clamp(0.5rem, 1vw, 2.3rem);
}

.card-price {
    margin-bottom: 3px;
    font-size: clamp(0.8rem, 1.2vw, 2.5rem);
}`, "",{"version":3,"sources":["webpack://./src/modules/Insumos/Producto_List.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,sBAAsB;IACtB,kBAAkB;IAClB,gDAAgD;AACpD;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;IACb,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,yBAAyB;IACzB,YAAY;AAChB;;AAEA;IACI,uCAAuC;AAC3C;;AAEA;IACI,kBAAkB;IAClB,qCAAqC;AACzC;;AAEA;IACI,kBAAkB;IAClB,uCAAuC;AAC3C","sourcesContent":[".ProductListRow {\n    background-color: white;\n    border: 3px solid #ccc;\n    border-radius: 5px;\n    box-shadow: 3px 3px 10px rgba(94, 94, 94, 0.528);\n}\n\n.ProductListCol {\n    margin: 10px;\n}\n\n.ProductListCol > .div-button {\n    align-items: center;\n    justify-content: center;\n    display: flex;\n    margin-top: 10px;\n    margin-bottom: 10px;\n}\n\n.ProductListCol > .div-button > .btn-outline-light {\n    background-color: #9fe4ff;\n    color: black;\n}\n\n.card-title {\n    font-size: clamp(0.8rem, 1.2vw, 2.5rem);\n}\n\n.card-subtitle {\n    margin-bottom: 3px;\n    font-size: clamp(0.5rem, 1vw, 2.3rem);\n}\n\n.card-price {\n    margin-bottom: 3px;\n    font-size: clamp(0.8rem, 1.2vw, 2.5rem);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
